import React from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {Animated} from "react-animated-css";

const initData = {
    heading: stringsTranslate(words.words.let_everyone_know_q),
    headingTwo: stringsTranslate(words.words.let_everyone_know_a),
    content: "",
    btnText: "Start free trail",
    iconClass: "fas fa-play",
    heroThumb: "/img/logo/new-logo/qr-website.png"
}

export default function HeroSection() {



        return (
            <section id="home" className="section welcome-area bg-overlay d-flex align-items-center overflow-hidden ">
                <div className="container">
                    <div className="row align-items-center pt-5 mt-2">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-5 col-lg-5">
                            <div className="welcome-intro">
                                <h2 className="text-heading-home-page">{initData.heading}<br/>
                                </h2>
                                <a className="text-subheading-home-page text-white mt-5 pt-5 border-bottom" href={"/sticker-application"}>{initData.headingTwo}<br/>
                                </a>
                                {/*<p className="text-white my-4">{initData.content}</p>*/}
                                {/* Subscribe Form */}
                                {/*<div className="subscribe-form d-flex align-items-center">*/}
                                {/*    <input type="email" className="form-control" placeholder="info@yourmail.com"/>*/}
                                {/*    <button type="submit"*/}
                                {/*            className="btn btn-bordered">{this.state.data.btnText}</button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-12 col-md-7 col-lg-7">
                            {/* Welcome Thumb */}
                            <Animated className="text-sm-center" animationInDelay={150} animationIn="jackInTheBox" isVisible={true}>
                                {/* Play Button */}
                                <img src={initData.heroThumb} className={"border-radius-img"} alt="" width={350}/>
                            </Animated>
                        </div>
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape-bottom">
                    <svg viewBox="0 0 1920 310" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg replaced-svg">
                        <defs />
                        <g id="sApp-Landing-Page" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g id="sApp-v1.0" transform="translate(0.000000, -554.000000)" fill="#FFFFFF">
                                <path d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395" id="sApp-v1.0" />
                            </g>
                        </g>
                    </svg>
                </div>
            </section>
        );
    }
